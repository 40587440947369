import React, { useState } from "react"
import { times } from "lodash"
import { PlayIcon } from '@heroicons/react/24/outline'

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import WistiaPlayer from "@/components/WistiaPlayer"
import { graphql, Link } from "gatsby"
import { routes } from "@/utils/routes"
import Feature from "@/components/Feature"
import VideoModal from "@/components/VideoModal"

type PageProps = {
  data: any
}

export const pageQuery = graphql`
query SchoolProjectsQuery {
  sanityVideo(position: {eq: 2}) {
    title
    placeholder {
      asset {
        url
      }
    }
    videoUrl
  }
}
`

const SchoolProjects: React.FC<PageProps> = ({ data }: { data: any }) => {
  return (
    <Layout
      sectionOne={
        <>
          <StaticImage
            src="../../static/images/transform-1.jpg"
            className="absolute inset-0 -z-[1]"
            alt="School Projects"
          />
          <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
            <h1 className="pb-2 md:pb-8 responsive-heading">
              SCHOOL <span className="text-primary">PROJECTS</span>
            </h1>
            <div className="pt-8 flex justify-center">
              <img src="/images/down.png" alt="Down arrow" />
            </div>
          </div>
        </>
      }
    >
      <SEO title="School Projects" />

      <div className="py-24">
        <div className="container">
          <h2 className="uppercase text-[36px] sm:text-[46px] font-medium leading-[0.9] pb-4 mb-8">
            Help us create a <span className="text-primary">learning</span>{" "}
            environment which will{" "}
            <span className="text-primary">inspire our pupils</span>
          </h2>

          {data.sanityVideo.videoUrl != null ?
            <WistiaPlayer
              videoStyle={{
                width: "100%",
                height: "100%"
              }}
              videoID={data.sanityVideo.videoUrl}
              className="aspect-video"
            />
            :
            <img src={data.sanityVideo.placeholder.asset.url} alt={data.sanityVideo.title} className="w-full" />
          }

          <div className="md:mt-8 mb-8 grid gap-8 grid-cols-1 md:grid-cols-2">
            <div>
            </div>

            <div className="w-full uppercase flex items-center justify-self-end">
              <h2 className="text-[42px] leading-none pt-4 lg:pl-12">
                <Link
                  className="text-primary hover:opacity-90"
                  to={routes.donate()}
                >
                  Donate Now
                </Link>
              </h2>
            </div>
          </div>

          <p className="leading-relaxed mt-4 text-center md:text-left text-lg md:text-xl">
            We are seeking:
          </p>

          <div className="grid gap-y-8 md:grid-cols-2 lg:grid-cols-4 pt-8">
            <Feature description="£10,000 to help fund state of the art cameras and screens to the Common Hall, enhancing school concerts, plays and weekly assembly talks." />
            <Feature description="£5,000 to upgrade our drama rehearsal studio and put momentum back into performing arts at RBAI, following the pandemic hiatus." />
            <Feature description="£2,500 to equip our Eco, Art and Robotics clubs as their memberships grow." />
            <Feature description="£1,500 to upgrade video analysis equipment, widening access to more hockey and rugby teams to benefit from video analysis." />
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default SchoolProjects
